body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F1F1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/************ Amplify UI components style rules ************/
[data-amplify-authenticator] {
  --amplify-components-authenticator-router-border-color: var(--amplify-components-authenticator-router-background-color);
}

div#root [data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none;
}

@media (min-width: 30rem) {
  div#root [data-amplify-authenticator] [data-amplify-container] {
    width: 35rem;
  }
  
  div#root [data-amplify-authenticator] [data-amplify-form] {
    padding: var(--amplify-components-authenticator-form-padding) calc(var(--amplify-components-authenticator-form-padding)*3.0);
  }
}

.amplify-button[data-variation='primary']{
  background: #0F356F;
}

.amplify-button[data-variation='primary']:hover {
  background: #002884;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.amplify-button[data-variation='primary']:active,.amplify-button[data-variation='primary']:focus {
  background: #0F356F;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.amplify-button[data-variation='link'] {
  color: #0F356F;
}

/************ END Amplify's UI components style rules ************/